
import $app from '@/plugins/modules'
import { System } from '@/modules/system'
import { Vue, Component, Prop } from 'vue-property-decorator'
import { IPatient, IVisit } from '../types';

@Component
export default class CustomerVisitsTab extends Vue {
  @Prop({ default: () => { } })
  readonly patient: IPatient | undefined;

  system = $app.module(System);

  ipp = $app.module(System).$store.getCurrentUser.rowsPerPage;
  showFilter = false;
  visitVisible = false;
  visitSelected: number | null = null;

  filterFrom = new Date().addMonth(-1).native(false);
  filterTo = new Date().addMonth(1).native(false);

  get headers() {
    return [
      {
        text: '',
        value: 'state',
        align: 'left',
        sortable: false,
        width: '50px'
      },
      {
        text: $app.i18n('system.Date'),
        value: 'visitDate',
        align: 'left',
        width: '20%'
      },
      {
        text: $app.i18n('system.Time'),
        value: 'timeFrom',
        align: 'left',
        width: '15%'
      },
      {
        text: $app.i18n('system.Number'),
        value: 'visitNumber',
        align: 'left',
        width: '100px'
      },
      {
        text: $app.i18n('system.Doctor'),
        value: 'doctorName',
        align: 'left',
        width: '20%'
      },
      {
        text: $app.i18n('dplus.visits.Edit.CancelCode'),
        value: 'cancelCode',
        align: 'left',
        width: '10%'
      },
      {
        text: $app.i18n('dplus.customers.Edit.VisitTotal'),
        value: 'totalAmount',
        align: 'right',
        width: '15%'
      },
      {
        text: $app.i18n('dplus.customers.Edit.VisitDebt'),
        value: 'VisitDebt',
        align: 'right',
        sortable: false,
        width: '15%'
      },
      {
        text: $app.i18n('dplus.customers.Visit.Payed'),
        value: 'visitPayed',
        align: 'center',
        width: '15%'
      }
    ];
  }

  get company() {
    return this.system.$store.getCurrentUser.companyId;
  }

  get visits(): Array<IVisit> {
    const from = new Date(this.filterFrom);
    const to = new Date(this.filterTo);

    return !this.patient || !this.patient.visits
      ? []
      : this.patient.visits.filter(v => {
        if (this.showFilter) {
          return new Date(v.visitDate).getTime() >= from.getTime() && new Date(v.visitDate).getTime() < to.getTime();
        } else {
          return v.state === 'P' || v.state === 'A' || v.state === 'T' || ((v.payedAmount + v.prepaid) - v.totalAmount < 0)
        }
      });
  }

  async open(visit: IVisit) {
    if (this.patient) {
      this.visitSelected = visit.id;
      this.visitVisible = true;
    }
  }

  hasDebt(visit: IVisit): boolean {
    return !visit ? true : ((visit.payedAmount || 0) + visit.prepaid) < (visit.totalAmount || 0);
  }

  debt(visit: IVisit): number {
    return !visit ? 0 : ((visit.payedAmount || 0) + visit.prepaid) - (visit.totalAmount || 0);
  }

  append() {
    if (this.patient) {
      this.visitSelected = null;
      this.visitVisible = true;
    }
  }

  updateVisit(visit: IVisit) {
    if (this.patient) {
      const index = this.patient.visits.findIndex(v => v.id === visit.id);
      if (index >= 0) {
        this.patient.visits.splice(index, 1, visit);
      } else {
        this.patient.visits.push(visit);
      }
    }
  }
}
